export type Lookup = {
  id: number | string;
  value: string;
};

export enum LookupKey {
  Quarter = "quarters",
  Scope = "scopes",
  AssetType = "assettypes",
  Asset = "assets",
  EnergyType = "energytypes",
  Purpose = "purposes",
  MaterialType = "materialtypes",
  MaterialSubtype = "materialsubtypes",
  WasteMaterial = "wastematerials",
  UtilitySectors = "utilitysectors",
  Organisations = "organisations",
  UserRoles = "userroles",
}

export type AssetLookup = Lookup & {
  coreAsset: boolean;
};
