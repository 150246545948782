import { PrimaryButton } from "../PrimaryButton/PrimaryButton";
import "./FormButtons.scss";

interface FormButtonsProps {
  onBack: () => void;
  onSaveAndClose: () => void;
  onNext?: () => void;
  onSubmit?: () => void;
  disabled?: boolean;
}

export const FormButtons = ({
  onBack,
  onSaveAndClose,
  onNext,
  onSubmit,
  disabled,
}: FormButtonsProps) => {
  return (
    <div className="form-buttons">
      <PrimaryButton label="Back" onClick={onBack} disabled={disabled} />
      <PrimaryButton label="Save and Close" onClick={onSaveAndClose} disabled={disabled} />
      {onSubmit ? (
        <PrimaryButton label="Save and View Results" onClick={onSubmit} disabled={disabled} />
      ) : null}
      {onNext ? <PrimaryButton label="Next" onClick={onNext} disabled={disabled} /> : null}
    </div>
  );
};
