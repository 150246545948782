import { useNavigate, useParams } from "react-router-dom";
import { Waste, selectWaste, setStatus } from "../features/waste/wasteSlice";
import { useFormControl } from "./useFormControl";
import { useSave } from "./useSave";
import { ApiWasteMaterial } from "../models/formTypes";
import { useCalculate } from "./useCalculate";
import { FormStatus } from "../models/formStatus";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

type SaveWasteMaterialsRequest = {
  highLevelDetailsID: number;
  wasteMaterials: ApiWasteMaterial[];
};

const createSavePayload = (wasteMaterials: Waste[], workId: string): SaveWasteMaterialsRequest => {
  return {
    highLevelDetailsID: parseInt(workId),
    wasteMaterials: wasteMaterials.map((w) => ({
      highLevelDetailsID: parseInt(workId),
      wasteMaterialID: parseInt(w.wasteMaterialId || ""),
      quantity: parseInt(w.quantity),
      percentageRecycled: parseFloat(w.percentageRecycled),
      scopeID: parseInt(w.scopeId || ""),
    })),
  };
};

export const useWaste = () => {
  const waste = useSelector(selectWaste);
  const { closeEntry } = useFormControl();
  const { save, isSaving } = useSave<SaveWasteMaterialsRequest>();
  const navigate = useNavigate();
  const { mode, workId } = useParams();
  const { calculateResults } = useCalculate();
  const dispatch = useDispatch();

  const back = () => {
    navigate(`/${mode}/${workId}/asset-usage`);
  };

  const saveAndClose = async () => {
    if (!workId) return;

    const { success } = await save(createSavePayload(waste.form, workId), "/wastematerial/save");

    await calculateResults(workId);

    if (success) {
      closeEntry();
    }
  };

  const next = async () => {
    if (!workId) return;

    const { success } = await save(createSavePayload(waste.form, workId), "/wastematerial/save");

    await calculateResults(workId);

    if (success) {
      closeEntry();
      dispatch(setStatus(FormStatus.Complete));
      navigate(`/${mode}/${workId}/results`);
    }
  };

  return {
    back,
    saveAndClose,
    next,
    isSaving,
  };
};
