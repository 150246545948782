import React, { useState } from "react";
import { Card } from "../../Card/Card";
import Doughnut, { DoughnutData } from "../../Doughnut/Doughnut";
import "./ToggleableDoughnutCard.scss";
import { RadioButton } from "../../RadioButton/RadioButton";
import { LoadingSpinner } from "../../LoadingSpinner/LoadingSpinner";

interface DoughnutCardProps {
  data: DoughnutData[] | undefined;
  isLoading: boolean;
  titlePlaceholder?: string;
  totalModifier?: (value: number) => string;
  subtitle?: string;
}

export const ToggleableDoughnutCard = ({
  data,
  isLoading,
  titlePlaceholder,
  totalModifier,
  subtitle,
}: DoughnutCardProps) => {
  const [selectedDataIndex, setSelectedDataIndex] = useState<number>(0);

  const handleRadioChange = (index: number) => {
    setSelectedDataIndex(index);
  };

  return (
    <div className="toggleable-container">
      <div className="toggleable-container__card">
        <Card
          title={data && data.length ? data[selectedDataIndex].title : titlePlaceholder}
          subtitle={subtitle}
        >
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              {data && data.length ? (
                <div className="toggleable-container__doughnut">
                  <Doughnut data={data[selectedDataIndex]} legend totalModifier={totalModifier} />
                </div>
              ) : (
                <div>No data available to display</div>
              )}
            </>
          )}
        </Card>
      </div>
      <div className="toggleable-container__data-toggle">
        <div className="toggleable-container__data-toggle__radio-list">
          {data &&
            data.map((d, index) => (
              <React.Fragment key={index}>
                <RadioButton
                  label={d.title}
                  checked={index === selectedDataIndex}
                  onChange={() => handleRadioChange(index)}
                />
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
};
