import "./EnergyConsumerRow.scss";
import { useLookup } from "../../../hooks/useLookup";
import { LookupKey } from "../../../models/lookup";
import { Select, NumberInput } from "../../FormComponents";
import { DeleteButton } from "../../DeleteButton/DeleteButton";
import { EnergyConsumer } from "../../../features/energyUsage/energyUsageSlice";

interface EnergyConsumerRowProps {
  energyConsumer: EnergyConsumer;
  onRemove: (energyConsumerId: string | null) => void;
  onChange: (a: EnergyConsumer) => void;
  disabled?: boolean;
}

export const EnergyConsumerRow = ({
  energyConsumer,
  onRemove,
  onChange,
  disabled,
}: EnergyConsumerRowProps) => {
  const { id, energyTypeId, purposeId, quantity, scopeId } = energyConsumer;
  const { values: energyTypes } = useLookup(LookupKey.EnergyType);
  const { values: purposes } = useLookup(LookupKey.Purpose);
  const { values: scopes } = useLookup(LookupKey.Scope);

  const handleEnergyTypeChange = (e: React.FormEvent<HTMLSelectElement>) => {
    onChange({
      ...energyConsumer,
      energyTypeId: e.currentTarget.value,
    });
  };

  const handlePurposeChange = (e: React.FormEvent<HTMLSelectElement>) => {
    onChange({
      ...energyConsumer,
      purposeId: e.currentTarget.value,
    });
  };

  const handleQuantityChange = (quantity: string) => {
    return onChange({ ...energyConsumer, quantity: quantity });
  };

  const handleScopeChange = (e: React.FormEvent<HTMLSelectElement>) => {
    onChange({ ...energyConsumer, scopeId: e.currentTarget.value });
  };

  return (
    <div className="energy-consumer-row">
      <Select
        id={`energy-type-${id}`}
        label="Energy Type"
        options={energyTypes}
        value={energyTypeId}
        onChange={handleEnergyTypeChange}
        disabled={disabled}
      />
      <Select
        id={`purpose-${id}`}
        label="Purpose"
        options={purposes}
        value={purposeId}
        onChange={handlePurposeChange}
        disabled={disabled}
      />
      <NumberInput
        id={`quantity-${id}`}
        label="Quantity"
        value={quantity}
        min={0}
        onChange={handleQuantityChange}
        disabled={disabled}
      />
      <Select
        id={`scope-${id}`}
        label="Scope"
        options={scopes}
        value={scopeId}
        onChange={handleScopeChange}
        disabled={disabled}
      />

      <DeleteButton onClick={() => onRemove(energyTypeId)} disabled={disabled} />
    </div>
  );
};
