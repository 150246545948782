import { useMutation } from "react-query";
import { getAuthHeader } from "../utils/tokenUtils";
import { env } from "../env";
import toast from "react-hot-toast";

export const useCalculate = () => {
  const { mutateAsync } = useMutation(async (workId: string) => {
    const url = `${env.REACT_APP_API_BASE_URL}/emissions/calculate/${workId}`;

    const response = await fetch(url, {
      method: "POST",
      headers: { Authorization: getAuthHeader() },
    });

    return response.ok;
  });

  const calculateResults = async (workId: string) => {
    try {
      const success = await mutateAsync(workId);

      if (!success) {
        toast.error("Failed to calculate results.");
      }
    } catch (e) {
      toast.error("Failed to calculate results.");
    }
  };

  return {
    calculateResults,
  };
};
