import { Waste } from "../../../features/waste/wasteSlice";
import { useLookup } from "../../../hooks/useLookup";
import { LookupKey } from "../../../models/lookup";
import { DeleteButton } from "../../DeleteButton/DeleteButton";
import { NumberInput, Select } from "../../FormComponents";
import "./WasteRow.scss";

interface WasteRowProps {
  waste: Waste;
  onRemove: (a: string | null) => void;
  onChange: (a: Waste) => void;
  disabled?: boolean;
}

export const WasteRow = ({ waste, onRemove, onChange, disabled }: WasteRowProps) => {
  const { id, wasteMaterialId, quantity, percentageRecycled, scopeId } = waste;
  const { values: wasteMaterials } = useLookup(LookupKey.WasteMaterial);
  const { values: scopes } = useLookup(LookupKey.Scope);

  const handleWasteMaterialChange = (e: React.FormEvent<HTMLSelectElement>) => {
    onChange({ ...waste, wasteMaterialId: e.currentTarget.value });
  };

  const handleQuantityChange = (quantity: string) => {
    return onChange({ ...waste, quantity: quantity });
  };

  const handlePercentageRecycledChange = (percentageRecycled: string) => {
    return onChange({ ...waste, percentageRecycled: percentageRecycled });
  };

  const handleScopeChange = (e: React.FormEvent<HTMLSelectElement>) => {
    onChange({ ...waste, scopeId: e.currentTarget.value });
  };

  return (
    <div className="waste-row">
      <Select
        id={`waste-material-${id}`}
        label="Waste Material"
        options={wasteMaterials}
        value={wasteMaterialId}
        onChange={handleWasteMaterialChange}
        disabled={disabled}
      />
      <NumberInput
        id={`quantity-${id}`}
        label="Quantity"
        value={quantity}
        min={0}
        onChange={handleQuantityChange}
        disabled={disabled}
      />
      <NumberInput
        id={`percentage-recycled-${id}`}
        label="Recycled"
        value={percentageRecycled}
        min={0}
        max={100}
        onChange={handlePercentageRecycledChange}
        disabled={disabled}
        percentage={true}
      />
      <Select
        id={`scope-${id}`}
        label="Scope"
        options={scopes}
        value={scopeId}
        onChange={handleScopeChange}
        disabled={disabled}
      />

      <DeleteButton onClick={() => onRemove(wasteMaterialId)} disabled={disabled} />
    </div>
  );
};
