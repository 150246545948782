import { useNavigate, useParams } from "react-router-dom";
import {
  EnergyConsumer,
  selectEnergyUsage,
  setStatus,
} from "../features/energyUsage/energyUsageSlice";
import { useFormControl } from "./useFormControl";
import { useSave } from "./useSave";
import { ApiEnergyConsumer } from "../models/formTypes";
import { useCalculate } from "./useCalculate";
import { FormStatus } from "../models/formStatus";
import { useDispatch, useSelector } from "react-redux";

type SaveEnergyUsageRequest = {
  highLevelDetailsID: number;
  energyUsages: ApiEnergyConsumer[];
};

const createSavePayload = (
  energyConsumers: EnergyConsumer[],
  workId: string
): SaveEnergyUsageRequest => {
  return {
    highLevelDetailsID: parseInt(workId),
    energyUsages: energyConsumers.map((e) => ({
      highLevelDetailsID: parseInt(workId),
      energyTypeID: parseInt(e.energyTypeId || ""),
      purposeID: parseInt(e.purposeId || ""),
      quantity: parseInt(e.quantity),
      scopeID: parseInt(e.scopeId || ""),
    })),
  };
};

export const useEnergyUsage = () => {
  const energyUsage = useSelector(selectEnergyUsage);
  const { closeEntry } = useFormControl();
  const { save, isSaving } = useSave<SaveEnergyUsageRequest>();
  const navigate = useNavigate();
  const { mode, workId } = useParams();
  const { calculateResults } = useCalculate();
  const dispatch = useDispatch();

  const back = () => {
    navigate(`/new/${workId}/basic-details`);
  };

  const saveAndClose = async () => {
    if (!workId) return;

    const { success } = await save(
      createSavePayload(energyUsage.form, workId),
      "/energyusage/save"
    );

    await calculateResults(workId);

    if (success) {
      closeEntry();
    }
  };

  const next = async () => {
    if (!workId) return;

    const { success } = await save(
      createSavePayload(energyUsage.form, workId),
      "/energyusage/save"
    );

    if (success) {
      navigate(`/${mode}/${workId}/material-usage`);
      dispatch(setStatus(FormStatus.Complete));
    }
  };

  return {
    back,
    saveAndClose,
    next,
    isSaving,
  };
};
