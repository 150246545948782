import { Asset } from "../features/assetUsage/assetUsageSlice";
import { BasicDetails } from "../features/basicDetails/basicDetailsSlice";
import { EnergyConsumer } from "../features/energyUsage/energyUsageSlice";
import { Material } from "../features/materialUsage/materialUsageSlice";
import { Waste } from "../features/waste/wasteSlice";
import {
  GetApiAsset,
  GetApiBasicDetails,
  GetApiEnergyConsumer,
  GetApiMaterial,
  GetApiWasteMaterial,
} from "../models/formTypes";

export const transformSavedBasicDetails = (apiBasicDetails: GetApiBasicDetails): BasicDetails => {
  return {
    description: apiBasicDetails.description,
    startDate: apiBasicDetails.startDate,
    endDate: apiBasicDetails.endDate,
  };
};

export const transformSavedAssets = (apiAssets: GetApiAsset[]): Asset[] => {
  return apiAssets.map((a) => ({
    id: a.id,
    assetTypeId: a.assetTypeID.toString(),
    assetId: a.assetID.toString(),
    quantity: a.quantity.toString(),
    percentageRecycled: a.percentageRecycled.toString(),
    scopeId: a.scopeID.toString(),
  }));
};

export const transformSavedEnergyConsumers = (
  apiConsumers: GetApiEnergyConsumer[]
): EnergyConsumer[] => {
  return apiConsumers.map((c) => ({
    id: c.id,
    energyTypeId: c.energyTypeID.toString(),
    purposeId: c.purposeID.toString(),
    quantity: c.quantity.toString(),
    scopeId: c.scopeID.toString(),
  }));
};

export const transformSavedMaterials = (apiMaterials: GetApiMaterial[]): Material[] => {
  return apiMaterials.map((m) => ({
    id: m.id,
    materialTypeId: m.materialTypeID.toString(),
    materialSubtypeId: m.materialSubtypeID.toString(),
    quantity: m.quantityUsed.toString(),
    percentageRecycled: m.percentageRecycled.toString(),
    scopeId: m.scopeID.toString(),
  }));
};

export const transformSavedWasteMaterials = (apiWasteMaterials: GetApiWasteMaterial[]): Waste[] => {
  return apiWasteMaterials.map((m) => ({
    id: m.id,
    wasteMaterialId: m.wasteMaterialID.toString(),
    quantity: m.quantity.toString(),
    percentageRecycled: m.percentageRecycled.toString(),
    scopeId: m.scopeID.toString(),
  }));
};
