import { useState } from "react";
import toast from "react-hot-toast";
import { HttpStatusCode } from "../models/httpStatusCode";
import { env } from "../env";
import { HttpMethod } from "../models/httpMethod";
import { getAuthHeader } from "../utils/tokenUtils";

export const useSave = <T, V = unknown>() => {
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const save = async (
    payload: T,
    url: string,
    method: HttpMethod = HttpMethod.POST
  ): Promise<{ success: boolean; response: V | null }> => {
    const loadingToast = toast.loading("Saving...");
    setIsSaving(true);

    try {
      const response = (await fetch(`${env.REACT_APP_API_BASE_URL}${url}`, {
        method: method,
        body: JSON.stringify(payload),
        headers: {
          "Authorization": getAuthHeader(),
          "Content-Type": "application/json",
        },
      })) as Response;

      const success =
        response.status === HttpStatusCode.Created ||
        response.status === HttpStatusCode.Ok ||
        response.status === HttpStatusCode.NoContent;

      const responseBody =
        response.status === HttpStatusCode.NoContent
          ? null
          : await response.json();

      if (success) {
        toast.success("Successfully saved.");
      } else {
        toast.error("Failed to save.");
      }

      return { success, response: responseBody };
    } catch (e) {
      toast.error(`Something went wrong while saving. ${e}`);
      return { success: false, response: null };
    } finally {
      toast.dismiss(loadingToast);
      setIsSaving(false);
    }
  };

  return {
    save,
    isSaving,
  };
};
