import { useMutation, useQuery } from "react-query";
import { env } from "../env";
import { getAuthHeader } from "../utils/tokenUtils";
import toast from "react-hot-toast";
import { ManagedUserToCreate } from "../features/managedUser/managedUserSlice";

export type ManagedUser = {
  id: string;
  email: string;
  organisationId: string;
  roleId: string;
};

export const useManagedUser = () => {
  const fetchManagedUsers = async () => {
    const url = `${env.REACT_APP_API_BASE_URL}/authentication/getAllUsers`;

    const response = await fetch(url, {
      headers: { Authorization: getAuthHeader() },
    });

    const managedUsersResponse = (await response.json()) as ManagedUser[];
    return managedUsersResponse;
  };

  const { data, isLoading, refetch } = useQuery("users", fetchManagedUsers);

  const createManagedUserMutation = useMutation(
    async (user: ManagedUserToCreate) => {
      const url = `${env.REACT_APP_API_BASE_URL}/authentication/register`;

      const response: Response = await fetch(url, {
        method: "POST",
        headers: { Authorization: getAuthHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(user),
      });

      return response.ok;
    },
    {
      onSuccess: (response: boolean) => {
        if (response) {
          toast.success(`User created`);
          refetch();
        } else {
          // N.b the formatting of the below string is reflected in the toast
          toast.error(
            `User not created - please ensure that: 
            
              - Email address is valid, 

              - Password contains at least 6 characters

              - Password contains at least one non-alphanumeric (!@%)

              - Password contains at least one digit (1-9)

              - Password contains at least one upper and lower case character`
          );
        }
      },
      onError: () => {
        toast.error("Error");
      },
    }
  );

  const createManagedUser = async (user: ManagedUserToCreate) =>
    await createManagedUserMutation.mutateAsync(user);

  const deleteManagedUserMutation = useMutation(
    async (email: string) => {
      const url = `${env.REACT_APP_API_BASE_URL}/authentication/deleteuser`;
      const response = await fetch(url, {
        method: "DELETE",
        headers: { Authorization: getAuthHeader(), "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });

      return response.ok;
    },
    {
      onSuccess: (response: boolean) => {
        if (response) {
          toast.success(`User deleted`);
          refetch();
        } else {
          toast.error("User not deleted");
        }
      },
      onError: () => {
        toast.error("Error");
      },
    }
  );

  const deleteManagedUser = async (email: string) => {
    if (window.confirm("Are you sure you want to delete?"))
      await deleteManagedUserMutation.mutateAsync(email);
  };

  return { data, isLoading, createManagedUser, deleteManagedUser };
};
