declare global {
  interface Window {
    env: EnvironmentVariables;
  }
}

type EnvironmentVariables = {
  REACT_APP_API_BASE_URL: string;
};

export const env: EnvironmentVariables = { ...process.env, ...window.env };
