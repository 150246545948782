import "./HeaderAction.scss";

interface HeaderActionProps {
  label: string;
  inputId: string;
  children: React.ReactNode;
}

export const HeaderAction = ({ label, inputId, children }: HeaderActionProps) => {
  return (
    <div className="header-action">
      <label htmlFor={inputId}>{`${label}:`}</label>
      {children}
    </div>
  );
};
