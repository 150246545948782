import { Card } from "../../components/Card/Card";
import { Select, TextInput } from "../../components/FormComponents";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { PrimaryButton } from "../../components/PrimaryButton/PrimaryButton";
import Table, { RowData } from "../../components/Table/Table";
import { useLookup } from "../../hooks/useLookup";
import { useOrganisation } from "../../hooks/useOrganisation";
import { useManagedUser } from "../../hooks/useManagedUser";
import { LookupKey } from "../../models/lookup";
import "./Management.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  organisationForm,
  resetOrganisationFormState,
  setOrganisationName,
  setOrganisationUtilitySector,
} from "../../features/organisation/organisationSlice";
import {
  managedUserForm,
  resetManagedUserFormState,
  setManagedUserEmail,
  setManagedUserOrganisationId,
  setManagedUserPassword,
  setManagedUserRoleId,
} from "../../features/managedUser/managedUserSlice";

const Management = () => {
  const { data: managedUsers, createManagedUser, deleteManagedUser } = useManagedUser();
  const { data: organisations, createOrganisation, deleteOrganisation } = useOrganisation();
  const { values: utilitySectors } = useLookup(LookupKey.UtilitySectors);
  const { values: organisationOptions } = useLookup(LookupKey.Organisations);
  const { values: roles } = useLookup(LookupKey.UserRoles);

  const dispatch = useDispatch();

  const { organisation } = useSelector(organisationForm);
  const { user: managedUser } = useSelector(managedUserForm);

  const organisationsRowData: RowData[] = [];
  organisations?.forEach((organisation) => {
    organisationsRowData.push({
      ...organisation,
      sector: utilitySectors.find((u) => u.id === organisation.utilitySectorID)?.value,
      actions: (
        <div className="actions">
          <PrimaryButton
            aria-label="Delete Organisation"
            label="Delete"
            onClick={() => deleteOrganisation(organisation.organisationId)}
          />
        </div>
      ),
    });
  });

  organisationsRowData.push({
    name: (
      <TextInput
        id={"organisation-name"}
        label={"Name"}
        value={organisation.name}
        onChange={(e) => dispatch(setOrganisationName(e.currentTarget.value))}
      ></TextInput>
    ),
    sector: (
      <Select
        id="sectorId"
        label="Sector"
        value={""}
        options={utilitySectors}
        onChange={(e) => dispatch(setOrganisationUtilitySector(Number(e.currentTarget.value)))}
      />
    ),
    actions: (
      <div className="actions">
        <PrimaryButton
          label={"Add Organisation"}
          onClick={async () => {
            if (await createOrganisation(organisation)) {
              dispatch(resetOrganisationFormState());
            }
          }}
        />
      </div>
    ),
  });

  const usersRowData: RowData[] = [];
  managedUsers?.forEach((user) => {
    usersRowData.push({
      ...user,
      organisation: organisationOptions.find((o) => o.id === user.organisationId)?.value,
      password: "******",
      role: roles.find((r) => r.id === user.roleId)?.value,
      actions: (
        <div className="actions">
          <PrimaryButton
            aria-label="Delete User"
            label="Delete"
            onClick={() => deleteManagedUser(user.email)}
          />
        </div>
      ),
    });
  });

  usersRowData.push({
    email: (
      <TextInput
        id={"user-email"}
        label={"Email"}
        value={managedUser.email}
        onChange={(e) => dispatch(setManagedUserEmail(e.currentTarget.value))}
      />
    ),
    password: (
      <TextInput
        id={"user-password"}
        label={"Password"}
        type="password"
        value={managedUser.password}
        onChange={(e) => dispatch(setManagedUserPassword(e.currentTarget.value))}
      />
    ),
    organisation: (
      <Select
        id="organisation"
        label="Organisation"
        value={managedUser.organisationId}
        options={organisationOptions}
        onChange={(e) => dispatch(setManagedUserOrganisationId(e.currentTarget.value))}
      />
    ),
    role: (
      <Select
        id="Role"
        label="Role"
        value={managedUser.roleId}
        options={roles}
        onChange={(e) => dispatch(setManagedUserRoleId(e.currentTarget.value))}
      />
    ),
    actions: (
      <div className="actions">
        <PrimaryButton
          label={"Add User"}
          onClick={async () => {
            if (await createManagedUser(managedUser)) {
              dispatch(resetManagedUserFormState());
            }
          }}
        />
      </div>
    ),
  });

  return (
    <PageContainer heading="User Management">
      <div className="card-layout">
        <Card title="users">
          <Table
            columns={[
              {
                key: "email",
                header: "Email",
              },
              {
                key: "password",
                header: "Password",
              },
              {
                key: "organisation",
                header: "Organisation",
              },
              {
                key: "role",
                header: "Role",
              },
              {
                key: "actions",
                header: "Actions",
              },
            ]}
            data={usersRowData}
          />
        </Card>
        <Card title="organisations">
          <Table
            columns={[
              {
                key: "name",
                header: "Name",
              },
              {
                key: "sector",
                header: "Sector",
              },
              {
                key: "actions",
                header: "Actions",
              },
            ]}
            data={organisationsRowData}
          />
        </Card>
      </div>
    </PageContainer>
  );
};

export default Management;
