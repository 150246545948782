import { useQuery } from "react-query";
import { LookupKey } from "../models/lookup";
import { env } from "../env";
import { LookupResponse } from "./useLookup";
import { getAuthHeader } from "../utils/tokenUtils";

export const useSubLookup = (key: LookupKey, id: string | null) => {
  const fetchLookupValues = async () => {
    if (id === null) {
      return [];
    }
    const url = `${env.REACT_APP_API_BASE_URL}/lookups/${key}/${id}`;

    const response = await fetch(url, {
      headers: { Authorization: getAuthHeader() },
    });
    const lookupResponse = (await response.json()) as LookupResponse;

    return lookupResponse.lookUps;
  };

  const { data, isLoading } = useQuery(
    [`lookup-${key}`, id],
    fetchLookupValues
  );

  return { values: data || [], isLoading };
};
