import "./StatList.scss";
import { Card } from "../../Card/Card";
import { LoadingSpinner } from "../../LoadingSpinner/LoadingSpinner";
import { round } from "../../../utils/dataUtils";

export interface Stat {
  label: string;
  value: string;
  units: string;
}

interface StatListProps {
  title: string | undefined;
  stats: Stat[] | undefined;
  isLoading: boolean;
}

export const StatList = ({ title, stats, isLoading }: StatListProps) => {
  return (
    <Card title={title}>
      <div className="stat-list">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {stats && stats.length > 0 ? (
              stats.map((s, index) => (
                <div key={index} className="stat-list__row">
                  <span className="stat-list__stat__label">{`${s.label}:`}</span>
                  <span className="stat-list__row__stat">
                    <span className="stat-list__row__stat__value">
                      {round(Number(s.value), 0)}
                      <span className="stat-list__row__stat__units">{s.units}</span>
                    </span>
                  </span>
                </div>
              ))
            ) : (
              <div>No data available to display</div>
            )}
          </>
        )}
      </div>
    </Card>
  );
};
