import "./Card.scss";

interface CardProps {
  title?: string;
  subtitle?: string;
  children: React.ReactNode;
}

export const Card = ({ title, subtitle, children }: CardProps) => {
  return (
    <div className="card">
      <div className="card__title-container">
        {title && <h2 className="card__title">{title}</h2>}
        {subtitle && <h3 className="card__subtitle">{subtitle}</h3>}
      </div>
      {children}
    </div>
  );
};
