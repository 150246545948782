import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./styles/typography.scss";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { Toaster } from "react-hot-toast";
import { ResultsDashboard } from "./pages/ResultsDashboard/ResultsDashboard";
import { QueryClient, QueryClientProvider } from "react-query";
import { AssetUsage } from "./pages/AssetUsage/AssetUsage";
import { NewWorks } from "./pages/NewWorks/NewWorks";
import { EnergyUsage } from "./pages/EnergyUsage/EnergyUsage";
import { MaterialUsage } from "./pages/MaterialUsage/MaterialUsage";
import { Waste } from "./pages/Waste/Waste";
import { Login } from "./components/Login/Login";
import { ProtectedRoute } from "./components/ProtectedRoute/ProtectedRoute";
import { EntryDashboard } from "./pages/EntryDashboard/EntryDashboard";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import { SectorResultsDashboard } from "./pages/SectorResultsDashboard/SectorResultsDashboard";
import Management from "./pages/Management/Management";
import { adminAccess, openAccess, restrictedAccess } from "./utils/rolesUtils";
import { NotAuthorised } from "./pages/NotAuthorised/NotAuthorised";
import { Information } from "./pages/Information/Information";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute allowedRoles={openAccess}>
        <Information />
      </ProtectedRoute>
    ),
  },
  {
    path: "/entry-dashboard",
    element: (
      <ProtectedRoute allowedRoles={restrictedAccess}>
        <EntryDashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: "/:mode/basic-details",
    element: (
      <ProtectedRoute allowedRoles={restrictedAccess}>
        <NewWorks />
      </ProtectedRoute>
    ),
  },
  {
    path: "/:mode/:workId/basic-details",
    element: (
      <ProtectedRoute allowedRoles={restrictedAccess}>
        <NewWorks />
      </ProtectedRoute>
    ),
  },
  {
    path: "/:mode/:workId/asset-usage",
    element: (
      <ProtectedRoute allowedRoles={restrictedAccess}>
        <AssetUsage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/:mode/:workId/energy-usage",
    element: (
      <ProtectedRoute allowedRoles={restrictedAccess}>
        <EnergyUsage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/:mode/:workId/material-usage",
    element: (
      <ProtectedRoute allowedRoles={restrictedAccess}>
        <MaterialUsage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/:mode/:workId/waste",
    element: (
      <ProtectedRoute allowedRoles={restrictedAccess}>
        <Waste />
      </ProtectedRoute>
    ),
  },
  {
    path: "/:mode/:workId/results",
    element: (
      <ProtectedRoute allowedRoles={restrictedAccess}>
        <ResultsDashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: "/sector",
    element: (
      <ProtectedRoute allowedRoles={openAccess}>
        <SectorResultsDashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: "/user-management",
    element: (
      <ProtectedRoute allowedRoles={adminAccess}>
        <Management />
      </ProtectedRoute>
    ),
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/change-password",
    element: (
      <ProtectedRoute allowedRoles={openAccess}>
        <ChangePassword />
      </ProtectedRoute>
    ),
  },
  {
    path: "/contact-administrator",
    element: (
      <ProtectedRoute allowedRoles={openAccess}>
        <NotAuthorised />
      </ProtectedRoute>
    ),
  },
]);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Toaster />
        <RouterProvider router={router} />
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
