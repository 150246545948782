import "./MetricCard.scss";
import { Card } from "../../Card/Card";
import { LoadingSpinner } from "../../LoadingSpinner/LoadingSpinner";
import { round } from "../../../utils/dataUtils";

export interface Metric {
  title: string;
  value: number;
  units?: string;
}

interface MetricsProps {
  metrics: Metric[] | undefined;
  isLoading: boolean;
}

export const MetricCard = ({ metrics, isLoading }: MetricsProps) => {
  return (
    <Card>
      <div className="container">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {metrics && metrics.length ? (
              metrics.map((m, index) => (
                <div key={index} className="metric">
                  <h4 className="metric__title">{`${m.title}:`}</h4>
                  <div className="metric__value">
                    {round(m.value, 0)}
                    {m.units && <span className="metric__units">{m.units}</span>}
                  </div>
                </div>
              ))
            ) : (
              <div className="no-data">No data available to display</div>
            )}
          </>
        )}
      </div>
    </Card>
  );
};
