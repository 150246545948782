import "./FieldGroup.scss";

interface FieldGroupProps {
  children: React.ReactNode;
  heading?: string;
}

export const FieldGroup = ({ children, heading }: FieldGroupProps) => {
  return (
    <div className="field-group">
      {heading && <h2>{heading}</h2>}
      {children}
    </div>
  );
};
