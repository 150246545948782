import { useState } from "react";
import "./Date.scss";
import toast from "react-hot-toast";
import { formatDate } from "../../../utils/dateUtils";

interface DateProps {
  id: string;
  label?: string;
  value: string | number | readonly string[] | undefined;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  min?: string | number | undefined;
  max?: string | number | undefined;
}

export const DateInput = ({
  id,
  label,
  value,
  onChange,
  disabled = false,
  min,
  max,
}: DateProps) => {
  return (
    <div className="date">
      {label && (
        <label className={"date__label"} htmlFor={id}>
          {label}
        </label>
      )}
      <input
        id={id}
        className="date__input"
        type="date"
        onChange={onChange}
        disabled={disabled}
        value={value}
        max={max}
        min={min}
      />
    </div>
  );
};

interface DateRangeProps {
  id: string;
  dateFieldWrapper: React.ComponentType<{
    children: React.ReactNode;
    inputId: string;
    label: string;
  }>;
  label?: string;
  onChange: (dates: { startDate: string; endDate: string }) => void;
  endDateValue: string;
  startDateValue: string;
  disabled?: boolean;
  displayHorizontally?: boolean;
}

export const DateRange = ({
  id,
  onChange,
  disabled = false,
  dateFieldWrapper: DateFieldWrapper,
  displayHorizontally,
  startDateValue,
  endDateValue,
}: DateRangeProps) => {
  const [dates, setDates] = useState({
    startDate: startDateValue,
    endDate: endDateValue,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    const date = formatDate(value);

    if (date === "Invalid Date") return;
    const newDates = { ...dates, [id]: value };

    setDates(newDates);
    if (onChange) onChange(newDates);
  };

  return (
    <div className={`date${displayHorizontally ? "__horizontal" : ""}`}>
      <DateFieldWrapper inputId={`start-date-${id}`} label="From">
        <DateInput
          id="startDate"
          value={dates.startDate}
          label="Start"
          onChange={handleChange}
          disabled={disabled}
          max={dates.endDate}
        />
      </DateFieldWrapper>
      <DateFieldWrapper inputId={`end-date-${id}`} label="To">
        <DateInput
          id="endDate"
          value={dates.endDate}
          label="End"
          onChange={handleChange}
          min={dates.startDate}
          disabled={disabled}
        />
      </DateFieldWrapper>
    </div>
  );
};
