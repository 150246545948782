import TrashCanSolid from "../../assets/icons/trash-can-solid.svg";
import "./DeleteButton.scss";

interface DeleteButtonProps {
  onClick?: () => void;
  disabled?: boolean;
}

export const DeleteButton = ({
  onClick,
  disabled = false,
}: DeleteButtonProps) => {
  return (
    <button className="delete-button" onClick={onClick} disabled={disabled}>
      <img src={TrashCanSolid} alt="" />
    </button>
  );
};
