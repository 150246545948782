import { useQuery } from "react-query";
import { Lookup, LookupKey } from "../models/lookup";
import { env } from "../env";
import { getAuthHeader } from "../utils/tokenUtils";

export type LookupResponse = {
  lookUps: Lookup[];
  success: boolean;
  message: string | null;
};

export const useLookup = (key: LookupKey) => {
  const fetchLookupValues = async () => {
    const url = `${env.REACT_APP_API_BASE_URL}/lookups/${key}`;
    const response = await fetch(url, {
      headers: { Authorization: getAuthHeader() },
    });
    const lookupResponse = (await response.json()) as LookupResponse;

    return lookupResponse.lookUps;
  };

  const { data, isLoading } = useQuery(`lookup-${key}`, fetchLookupValues);

  return { values: data || [], isLoading };
};
