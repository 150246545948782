import { ToggleableDoughnutCard } from "../../components/Dashboard/ToggleableDoughnutCard/ToggleableDoughnutCard";
import { StatList } from "../../components/Dashboard/StatList/StatList";
import "./ResultsDashboard.scss";
import { Card } from "../../components/Card/Card";
import Table from "../../components/Table/Table";
import { MetricCard } from "../../components/Dashboard/MetricCard/MetricCard";
import { ResultsPageContainer } from "../../components/ResultsPageContainer/ResultsPageContainer";
import { useResults } from "../../hooks/useResults";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import { round, shortenResultsDataTotal } from "../../utils/dataUtils";
import { formatDate } from "../../utils/dateUtils";

export const ResultsDashboard = () => {
  const { data: results, isLoading } = useResults();

  return (
    <ResultsPageContainer
      heading={
        <div>
          Emissions
          {results ? (
            <>
              <i> from </i>
              {formatDate(results.startDate, "DD-MM-YYYY")}
              <i> until </i>
              {formatDate(results.endDate, "DD-MM-YYYY")}
            </>
          ) : (
            ""
          )}
        </div>
      }
    >
      <div className="dashboard">
        <div className="dashboard__row">
          <div className="dashboard__row__single">
            <StatList
              title={"top 5 emissions"}
              stats={results?.topFiveEmissions}
              isLoading={isLoading}
            />
          </div>
          <div className="dashboard__row__double">
            <ToggleableDoughnutCard
              data={results?.chartableData}
              titlePlaceholder="emissions breakdown"
              isLoading={isLoading}
              totalModifier={shortenResultsDataTotal}
            />
          </div>
        </div>

        <div className="dashboard__row">
          <div className="dashboard__row__double">
            <div className="detailed-emissions-table">
              <Card title="detailed emissions">
                {isLoading ? <LoadingSpinner /> : <div />}
                {results?.detailedEmissions ? (
                  <Table
                    columns={[
                      {
                        key: "source",
                        header: "Emission Source",
                      },
                      {
                        key: "quantity",
                        header: "Emissions",
                      },
                      {
                        key: "units",
                        header: "Units",
                      },
                    ]}
                    data={results.detailedEmissions.map((de) => ({
                      ...de,
                      quantity: round(Number(de.quantity)),
                    }))}
                  />
                ) : (
                  <div>No data available to display</div>
                )}
              </Card>
            </div>
          </div>
          <div className="dashboard__row__single">
            <MetricCard metrics={results?.highlightedMetrics} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </ResultsPageContainer>
  );
};
