import { useLocation } from "react-router-dom";
import { NavButton } from "../NavButton/NavButton";
import Logo from "../../assets/images/logo.png";
import "./SideNav.scss";
import { useAuth } from "../../hooks/useAuth";
import { selectUser } from "../../features/user/userSlice";
import { useSelector } from "react-redux";
import { adminAccess, openAccess, restrictedAccess } from "../../utils/rolesUtils";

export const SideNav = () => {
  const { pathname } = useLocation();
  const { logout } = useAuth();
  const { user } = useSelector(selectUser);

  return (
    <div className="side-nav">
      <div className="side-nav__logo">
        <img src={Logo} alt="Road To Net Zero Logo" />
      </div>

      <ul className="side-nav__main">
        {restrictedAccess.includes(user.role) && (
          <li>
            <NavButton
              label="Enter New Works"
              href="/new/basic-details"
              active={pathname.includes("new")}
            />
          </li>
        )}
        {restrictedAccess.includes(user.role) && (
          <li>
            <NavButton label="Entry Dashboard" href="/entry-dashboard" />
          </li>
        )}

        {openAccess.includes(user.role) && (
          <li>
            <NavButton
              label="Sector Results Dashboard"
              href="/sector"
              active={pathname === "/sector"}
            />
          </li>
        )}
      </ul>

      <ul className="side-nav__bottom">
        {openAccess.includes(user.role) && (
          <li>
            <NavButton label="Information" href="/" active={pathname === "/"} />
          </li>
        )}
        {adminAccess.includes(user.role) && (
          <li>
            <NavButton label="Admin" href="/user-management" />
          </li>
        )}
        {openAccess.includes(user.role) && (
          <li>
            <NavButton label="Change Password" href="/change-password" />
          </li>
        )}
        {openAccess.includes(user.role) && (
          <li>
            <NavButton label="Log Out" onClick={logout} />
          </li>
        )}
      </ul>
    </div>
  );
};
