import { SideNav } from "../SideNav/SideNav";
import "./SectorResultsPageContainer.scss";

interface ResultsPageContainerProps {
  children?: React.ReactNode;
  heading: React.ReactNode;
  actions?: React.ReactNode;
}

export const SectorResultsPageContainer = ({
  children,
  heading,
  actions,
}: ResultsPageContainerProps) => {
  return (
    <div className="sector-results-page-layout">
      <SideNav />
      <div className="sector-content-container">
        <div className="sector-content-container__top-bar">
          <h1 className="sector-content-container__top-bar__heading">{heading}</h1>
          <div className="sector-content-container__top-bar__actions">{actions}</div>
        </div>
        <div className="sector-content-container__content">{children}</div>
      </div>
    </div>
  );
};
