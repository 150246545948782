import { ReactNode, useState } from "react";
import ChevronRight from "../../assets/icons/chevron-right.svg";
import "./InformationalPane.scss";

interface InformationalPaneProps {
  bodyText: ReactNode;
}

const InformationalPane = ({ bodyText }: InformationalPaneProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`informational-pane ${isOpen ? "open" : ""}`}>
      <div className="accordion-header" onClick={toggleAccordion}>
        <div className="accordion-header__text">Click For More Info</div>
        <div className="accordion-header__icon">
          <img className={`arrow  ${isOpen ? "open" : ""}`} src={ChevronRight} alt="" />
        </div>
      </div>
      {isOpen && (
        <div className="accordion-content">
          <div className="body-text">{bodyText}</div>
        </div>
      )}
    </div>
  );
};

export default InformationalPane;
