import "./Doughnut.scss";

import ChartDataLabels from "chartjs-plugin-datalabels";

import { Chart as ChartJS, ArcElement, Tooltip, ChartData, ChartOptions } from "chart.js";
import { Doughnut as CJsDoughnut } from "react-chartjs-2";
import { round } from "../../utils/dataUtils";

ChartJS.register(ArcElement, Tooltip, ChartDataLabels);

export type DoughnutData = {
  title: string;
  total: number;
  units: string;
  values: {
    label: string;
    percentageOfTotal: number;
  }[];
};

interface DoughnutProps {
  data: DoughnutData;
  totalModifier?: (value: number) => string;
  legend?: boolean;
}

const Doughnut = ({ data, legend, totalModifier }: DoughnutProps) => {
  const backgroundColor = data.values.map((_, index: number) =>
    index % 2 === 0 ? "#a0c74d" : "#3c3c3c"
  );

  const labelColor = data.values.map((_, index: number) =>
    index % 2 === 0 ? "#3c3c3c" : "#a0c74d"
  );

  const chartData: ChartData<"doughnut", number[], string> = {
    labels: data.values.map((d) => d.label),
    datasets: [
      {
        data: data.values.map((d) => round(d.percentageOfTotal)),
        backgroundColor,
        borderColor: "white",
        borderWidth: 2,
      },
    ],
  };

  const chartOptions: ChartOptions<"doughnut"> = {
    events: [],
    plugins: {
      datalabels: {
        font: {
          size: 18,
          weight: 400,
          family: "Halcom",
        },
        color: labelColor,
        formatter: (value) => (value < 5 ? "" : `${round(value, 0)}%`),
      },

      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    cutout: "60%",
  };

  return (
    <div className="doughtnut-chart">
      <div className="doughtnut-chart__doughnut">
        <div className="doughtnut-chart__doughnut__centre-text">
          <p className="doughtnut-chart__doughnut__centre-text__value">
            {totalModifier ? totalModifier(data.total) : Math.trunc(data.total)}
          </p>
          <p className="doughtnut-chart__doughnut__centre-text__units">{data.units}</p>
        </div>
        <CJsDoughnut data={chartData} options={chartOptions} />
      </div>
      {legend && (
        <div className="legend">
          {data.values.map((d, index) => (
            <div key={index} className="legend__row">
              {index % 2 === 0 ? (
                <span className="legend__row__key--green" />
              ) : (
                <span className="legend__row__key--grey" />
              )}
              <div className="legend__row__label">{d.label}:</div>
              <span className="legend__row__value">{round(d.percentageOfTotal, 0)}%</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Doughnut;
