import { Dayjs } from "dayjs";
import { formatDate } from "../utils/dateUtils";

export type HighLevelDetail = {
  id: string;
  startDate: Dayjs;
  endDate: Dayjs;
  emissions: string;
  description: string;
};

type HighLevelDetailDTO = {
  id: number;
  startDate: Dayjs;
  endDate: Dayjs;
  description: string;
  organisationID: number;
  totalEmissions: string | null;
};

export const highLevelDetailsMapper = (jsonArray: HighLevelDetailDTO[]): HighLevelDetail[] => {
  return jsonArray.map((jsonObject) => ({
    id: jsonObject.id.toString(),
    startDate: jsonObject.startDate,
    endDate: jsonObject.endDate,
    emissions: jsonObject.totalEmissions || "-",
    description: jsonObject.description,
  }));
};
