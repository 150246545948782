import { Link } from "react-router-dom";
import "./PrimaryButton.scss";

interface PrimaryButtonProps {
  label: string;
  onClick?: () => void;
  href?: string;
  active?: boolean;
  disabled?: boolean;
}

export const PrimaryButton = ({
  label,
  onClick,
  href,
  active,
  disabled = false,
}: PrimaryButtonProps) => {
  const className = `primary-button ${active ? "primary-button--active" : ""} ${
    disabled ? "primary-button--disabled" : ""
  }`;

  if (href) {
    return (
      <Link className={className} to={href} onClick={onClick}>
        {label}
      </Link>
    );
  }

  return (
    <button className={className} onClick={onClick} disabled={disabled}>
      {label}
    </button>
  );
};
