import { Lookup } from "../../../models/lookup";
import "./Select.scss";

interface SelectProps {
  id: string;
  label: string;
  value: string | null;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  options: Lookup[];
  disabled?: boolean;
}

export const Select = ({ id, label, value, onChange, options, disabled = false }: SelectProps) => {
  const hasValue = () => value != null;

  return (
    <div className="select">
      <label className={`select__label ${hasValue() ? "select__label--filled" : ""}`} htmlFor={id}>
        {label}
      </label>
      <select id={id} onChange={onChange} value={value || undefined} disabled={disabled}>
        <option style={{ display: "none" }}></option>
        {options.map((o) => (
          <option key={o.value} value={o.id}>
            {o.value}
          </option>
        ))}
      </select>
    </div>
  );
};
