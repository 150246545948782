import "./Header.scss";

interface HeaderProps {
  label: string;
}

const Header = ({ label }: HeaderProps) => {
  return <div className="header">{label}</div>;
};

export default Header;
