import { PageContainer } from "../../components/PageContainer/PageContainer";
import Table, { RowData } from "../../components/Table/Table";
import { PrimaryButton } from "../../components/PrimaryButton/PrimaryButton";
import "./EntryDashboard.scss";
import { useHighLevelDetails } from "../../hooks/useHighLevelDetails";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils/dateUtils";

export const EntryDashboard = () => {
  const { data: highLevelDetails, isLoading, deleteHighLevelDetail } = useHighLevelDetails();

  const tableData: RowData[] = [];
  highLevelDetails?.forEach((highLevelDetail) => {
    tableData.push({
      startDate: formatDate(highLevelDetail.startDate, "DD-MM-YYYY"),
      endDate: formatDate(highLevelDetail.endDate, "DD-MM-YYYY"),
      emissions: highLevelDetail.emissions,
      description: highLevelDetail.description,
      actions: (
        <div className="actions">
          <Link
            aria-label="View Results"
            className="actions__link"
            to={`/edit/${highLevelDetail.id}/results`}
          >
            View Results
          </Link>
          <Link
            aria-label="Edit Entry"
            className="actions__link"
            to={`/edit/${highLevelDetail.id}/basic-details`}
          >
            Edit
          </Link>
          <PrimaryButton
            aria-label="Delete Entry"
            label="Delete"
            onClick={() => deleteHighLevelDetail(highLevelDetail.id)}
          />
        </div>
      ),
    });
  });

  return (
    <PageContainer
      heading="Entry Dashboard"
      informationalPaneBodyText="On this page, you can find all your company’s entries on the carbon calculator."
    >
      {isLoading ? (
        <div>It's loading!</div>
      ) : (
        <Table
          columns={[
            {
              key: "startDate",
              header: "Start Date",
            },
            {
              key: "endDate",
              header: "End Date",
            },
            {
              key: "emissions",
              header: "Emissions",
            },
            {
              key: "description",
              header: "Description",
            },
            {
              key: "actions",
              header: "",
            },
          ]}
          data={tableData}
        ></Table>
      )}
    </PageContainer>
  );
};
