import { useResults } from "../../hooks/useResults";
import { PrimaryButton } from "../PrimaryButton/PrimaryButton";
import { SideNav } from "../SideNav/SideNav";
import "./ResultsPageContainer.scss";

interface ResultsPageContainerProps {
  children?: React.ReactNode;
  heading: React.ReactNode;
  actions?: React.ReactNode;
}

export const ResultsPageContainer = ({ children, heading }: ResultsPageContainerProps) => {
  const { back, close } = useResults();
  return (
    <div className="results-page-layout">
      <SideNav />
      <div className="content-container">
        <div className="content-container__top-bar">
          <h1 className="content-container__top-bar__heading">{heading}</h1>
        </div>
        <div className="content-container__content">{children}</div>
        <div className="content-container__bottom-bar">
          <PrimaryButton label={"Back"} onClick={() => back()} />
          <PrimaryButton label={"Save and Close"} onClick={() => close()} />
        </div>
      </div>
    </div>
  );
};
