import { FieldGroup } from "../../components/FieldGroup/FieldGroup";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { TextInput } from "../../components/FormComponents";
import { InputRow } from "../../components/InputRow/InputRow";
import {
  selectBasicDetails,
  setDescription,
  setEndDate,
  setStartDate,
} from "../../features/basicDetails/basicDetailsSlice";
import { FormButtons } from "../../components/FormButtons/FormButtons";
import { useBasicDetails } from "../../hooks/useBasicDetails";
import { useDispatch, useSelector } from "react-redux";
import { DateRange } from "../../components/FormComponents/Date/Date";
import { EntryMode } from "../../models/entryMode";

export const NewWorks = () => {
  const { form } = useSelector(selectBasicDetails);
  const { saveAndClose, back, next, isSaving, isRestored, mode } = useBasicDetails();
  const dispatch = useDispatch();

  const infoPaneText = (
    <>
      The description field is a free-text box for any information that may be useful for you to
      make the entry more identifiable (i.e. South West Region, ACME Corp data etc.)
    </>
  );

  return (
    <PageContainer
      heading="Basic Details"
      informationalPaneBodyText={infoPaneText}
      displayNavigationPane
    >
      {mode !== EntryMode.Edit || isRestored === true ? (
        <FieldGroup>
          <DateRange
            id={"new-works-date-range"}
            onChange={({ startDate, endDate }) => {
              dispatch(setStartDate(startDate));
              dispatch(setEndDate(endDate));
            }}
            dateFieldWrapper={InputRow}
            startDateValue={form.startDate}
            endDateValue={form.endDate}
          />
          <InputRow inputId="description" label="Description">
            <TextInput
              id="description"
              label="Description"
              value={form.description}
              onChange={(e) => dispatch(setDescription(e.currentTarget.value))}
              disabled={isSaving}
            />
          </InputRow>
        </FieldGroup>
      ) : null}

      <FormButtons onBack={back} onSaveAndClose={saveAndClose} onNext={next} disabled={isSaving} />
    </PageContainer>
  );
};
