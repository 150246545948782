import { useState } from "react";
import { TextInput } from "../../components/FormComponents";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { PrimaryButton } from "../../components/PrimaryButton/PrimaryButton";
import { useAuth } from "../../hooks/useAuth";
import toast from "react-hot-toast";
import { isPasswordValid } from "./PasswordValidator";
import { InputRow } from "../../components/InputRow/InputRow";

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [enteredPassword, setEnteredPassword] = useState<string>("");
  const [confirmedPassword, setConfirmedPassword] = useState<string>("");

  const { changePassword, isLoading } = useAuth();

  const handleChangePassword = () => {
    const throwError = () =>
      toast.error(
        "Entered password must meet the following criteria: " +
          "at least 6 characters, contain at least one number, " +
          "one lowercase letter, one uppercase letter, " +
          "one non-alphanumeric character, and must match the confirmed password."
      );

    isPasswordValid(enteredPassword, confirmedPassword)
      ? changePassword(currentPassword, confirmedPassword)
      : throwError();
  };

  return (
    <PageContainer heading="Change Password">
      <div className="change-password">
        <InputRow inputId="current-password" label="Current password">
          <TextInput
            id="password"
            label="Enter current password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.currentTarget.value)}
            type="password"
            disabled={isLoading}
          />
        </InputRow>
        <InputRow inputId="current-password" label="New password">
          <TextInput
            id="new-password"
            label="Enter new password"
            value={enteredPassword}
            onChange={(e) => setEnteredPassword(e.currentTarget.value)}
            type="password"
            autocomplete="new-password"
            disabled={isLoading}
          />
        </InputRow>
        <InputRow inputId="current-password" label="Confirm password">
          <TextInput
            id="confirm-new-password"
            label="Confirm new password"
            value={confirmedPassword}
            onChange={(e) => setConfirmedPassword(e.currentTarget.value)}
            type="password"
            autocomplete="new-password"
            disabled={isLoading}
          />
        </InputRow>
        <PrimaryButton
          label="Update Password"
          onClick={handleChangePassword}
          disabled={isLoading}
        />
      </div>
    </PageContainer>
  );
};

export default ChangePassword;
