import "./AssetRow.scss";
import { useLookup } from "../../../hooks/useLookup";
import { LookupKey } from "../../../models/lookup";
import { Select, NumberInput } from "../../FormComponents";
import { DeleteButton } from "../../DeleteButton/DeleteButton";
import { Asset } from "../../../features/assetUsage/assetUsageSlice";
import { useSubLookup } from "../../../hooks/useSubLookup";

interface AssetRowProps {
  asset: Asset;
  onRemove: (assetId: string | null) => void;
  onChange: (a: Asset) => void;
  disabled?: boolean;
}

export const AssetRow = ({ asset, onRemove, onChange, disabled }: AssetRowProps) => {
  const { id, assetTypeId, assetId, quantity, percentageRecycled, scopeId } = asset;
  const { values: assetTypes } = useLookup(LookupKey.AssetType);
  const { values: assets } = useSubLookup(LookupKey.Asset, assetTypeId);
  const { values: scopes } = useLookup(LookupKey.Scope);

  const handleAssetTypeChange = (e: React.FormEvent<HTMLSelectElement>) => {
    onChange({ ...asset, assetTypeId: e.currentTarget.value, assetId: null });
  };

  const handleAssetChange = (e: React.FormEvent<HTMLSelectElement>) => {
    onChange({ ...asset, assetId: e.currentTarget.value });
  };

  const handleQuantityChange = (quantity: string) => {
    return onChange({ ...asset, quantity: quantity });
  };

  const handlePercentageRecycledChange = (percentageRecycled: string) => {
    return onChange({ ...asset, percentageRecycled: percentageRecycled });
  };

  const handleScopeChange = (e: React.FormEvent<HTMLSelectElement>) => {
    onChange({ ...asset, scopeId: e.currentTarget.value });
  };

  return (
    <div className="asset-row">
      <Select
        id={`asset-type-${id}`}
        label="Asset Type"
        options={assetTypes}
        value={assetTypeId}
        onChange={handleAssetTypeChange}
        disabled={disabled}
      />
      <Select
        id={`asset-${id}`}
        label="Asset"
        options={assets}
        value={assetId}
        onChange={handleAssetChange}
        disabled={disabled}
      />
      <NumberInput
        id={`quantity-${id}`}
        label="Quantity"
        value={quantity}
        min={0}
        onChange={handleQuantityChange}
        disabled={disabled}
      />
      <NumberInput
        id={`percentage-recycled-${id}`}
        label="Recycled"
        value={percentageRecycled}
        min={0}
        max={100}
        onChange={handlePercentageRecycledChange}
        disabled={disabled}
        percentage={true}
      />
      <Select
        id={`scope-${id}`}
        label="Scope"
        options={scopes}
        value={scopeId}
        onChange={handleScopeChange}
        disabled={disabled}
      />

      <DeleteButton onClick={() => onRemove(assetId)} disabled={disabled} />
    </div>
  );
};
