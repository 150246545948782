import { ToggleableDoughnutCard } from "../../components/Dashboard/ToggleableDoughnutCard/ToggleableDoughnutCard";
import { StatList } from "../../components/Dashboard/StatList/StatList";
import "./SectorResultsDashboard.scss";
import { MetricCard } from "../../components/Dashboard/MetricCard/MetricCard";
import { useSectorResults } from "../../hooks/useSectorResults";
import { Select } from "../../components/FormComponents";
import { useLookup } from "../../hooks/useLookup";
import { LookupKey } from "../../models/lookup";
import { HeaderAction } from "../../components/HeaderAction/HeaderAction";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSectorResultForm,
  setStartDate,
  setUtilitySectorId,
  setEndDate,
} from "../../features/sectorResultsSlice/sectorResultsSlice";
import { SectorResultsPageContainer } from "../../components/SectorResultsPageContainer/SectorResultsPageContainer";
import { shortenResultsDataTotal } from "../../utils/dataUtils";
import { DateRange } from "../../components/FormComponents/Date/Date";

export const SectorResultsDashboard = () => {
  const { utilitySectorId } = useSelector(selectSectorResultForm);
  const dispatch = useDispatch();

  const { values: utilitySectors } = useLookup(LookupKey.UtilitySectors);

  const { data: results, isFetching } = useSectorResults();

  return (
    <SectorResultsPageContainer
      heading={"Emissions by Date and Sector"}
      actions={
        <>
          <DateRange
            id={""}
            onChange={({ startDate, endDate }) => {
              dispatch(setStartDate(startDate));
              dispatch(setEndDate(endDate));
            }}
            dateFieldWrapper={HeaderAction}
            displayHorizontally
            startDateValue={""}
            endDateValue={""}
          />
          <HeaderAction inputId="sectorId" label="Sector">
            <Select
              id="sectorId"
              label="Sector"
              value={utilitySectorId}
              options={utilitySectors}
              onChange={(e) => dispatch(setUtilitySectorId(e.currentTarget.value))}
            />
          </HeaderAction>
        </>
      }
    >
      <div className="sector-dashboard">
        <div className="sector-dashboard__row">
          <div className="sector-dashboard__row__single">
            <div className="sector-dashboard__row__single">
              <MetricCard metrics={results?.highlightedMetrics} isLoading={isFetching} />
            </div>
          </div>
          <div className="sector-dashboard__row__double">
            <ToggleableDoughnutCard
              data={results?.chartableData}
              titlePlaceholder="emissions breakdown"
              isLoading={isFetching}
              totalModifier={shortenResultsDataTotal}
              subtitle={
                results?.respondents
                  ? `${results.respondents.toString()} Respondents`
                  : "0 Respondents"
              }
            />
          </div>
        </div>
        <div className="sector-dashboard__row__triple">
          <StatList
            title={"top 5 emissions"}
            stats={results?.topFiveEmissions}
            isLoading={isFetching}
          />
        </div>
      </div>
    </SectorResultsPageContainer>
  );
};
