import { ReactNode } from "react";
import InformationalPane from "../InformationalPane/InformationalPane";
import { NavigationPane } from "../NavigationPane/NavigationPane";
import { SideNav } from "../SideNav/SideNav";
import "./PageContainer.scss";

interface PageContainerProps {
  children?: React.ReactNode;
  heading: string;
  displayNavigationPane?: boolean;
  informationalPaneBodyText?: ReactNode;
}

export const PageContainer = ({
  children,
  heading,
  displayNavigationPane = false,
  informationalPaneBodyText,
}: PageContainerProps) => {
  return (
    <div className="page-container">
      <SideNav />
      <div
        className={`page-container__child-container ${
          !displayNavigationPane ? "page-container__child-container--no-nav" : ""
        }`}
      >
        <div className="title-box">
          <h1 className="page-container__child-container__heading">{heading}</h1>
          {informationalPaneBodyText && <InformationalPane bodyText={informationalPaneBodyText} />}
        </div>

        <div className="page-container__child-container__navigation">
          {displayNavigationPane ? <NavigationPane /> : null}
        </div>

        <div className="page-container__child-container__content">
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};
