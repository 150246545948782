import { FieldGroup } from "../../components/FieldGroup/FieldGroup";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import {
  addAsset,
  removeAsset,
  selectAssetUsage,
  updateAsset,
} from "../../features/assetUsage/assetUsageSlice";
import { FormButtons } from "../../components/FormButtons/FormButtons";
import { AssetRow } from "../../components/FieldRows/AssetRow/AssetRow";
import { PrimaryButton } from "../../components/PrimaryButton/PrimaryButton";
import { useAssetUsage } from "../../hooks/useAssetUsage";
import AssetHeaders from "../../components/FieldHeaders/AssetHeaders/AssetHeaders";
import { useDispatch, useSelector } from "react-redux";

export const AssetUsage = () => {
  const { form } = useSelector(selectAssetUsage);
  const { back, saveAndClose, next, isSaving } = useAssetUsage();
  const dispatch = useDispatch();

  const infoPaneText = (
    <>
      Please include the various temporary traffic management (TTM) products used in your
      road/street works here. <br />
      <br />
      Other assets such as PPE and utility specific assets will be added in a future release of the
      tool. <br />
      <br />
      If known, please include what percentage of the assets used are made from recycled materials.
      <br />
      <br />
      For Scope, please add whether this is from in-house usage or from contractor usage. If
      unknown, or a combination of the two, please use <i>both</i> <br />
      <br />
      At any point, you can <i>Save and Close</i>. You will be able to return to the same point
      using the Entry Dashboard. <br />
      <br />
      Note, if any significant sources of emissions are missing from this section, please contact
      the team at <a href="mailto: roadtonetzero@eatechnology.com">RTNZ Email</a>
      <br />
    </>
  );

  return (
    <PageContainer
      heading="Asset Usage"
      informationalPaneBodyText={infoPaneText}
      displayNavigationPane
    >
      <FieldGroup>
        <AssetHeaders />
        {form.map((asset) => (
          <AssetRow
            asset={asset}
            key={asset.id}
            onRemove={() => dispatch(removeAsset(asset))}
            onChange={(updatedAsset) => dispatch(updateAsset(updatedAsset))}
            disabled={isSaving}
          />
        ))}
        <PrimaryButton
          onClick={() => dispatch(addAsset())}
          label="Add New Asset"
          disabled={isSaving}
        />
      </FieldGroup>

      <FormButtons onBack={back} onSaveAndClose={saveAndClose} onNext={next} disabled={isSaving} />
    </PageContainer>
  );
};
