import { PageContainer } from "../../components/PageContainer/PageContainer";
import "./Information.scss";

export const Information = () => {
  return (
    <PageContainer heading="Information:">
      <div className="body-text-container">
        Welcome to the Road to Net Zero Carbon Calculator! This tool has been developed in
        collaboration with the Street and Road works sector to gain a better understanding of how
        the sector is performing on its journey to net zero. We've designed the tool with simplicity
        in mind, aiming to maximise its usage by maintaining a high-level granularity in the data
        captured. <br /> <br />
        <h2>Background Information:</h2>
        <br />
        Emissions tracking in the Street and Road works sector is particularly challenging due to
        the large number of works involving multiple stakeholders. This complexity renders the
        assessment of emissions for individual works extremely resource-intensive and, as feedback
        from stakeholders suggests, impractical. <br /> <br />
        This tool is the first step in trying to improve data capturing processes in the industry
        and gain sector understanding on the impact these works have on overall emissions. The tool
        focuses on the capital emissions section (Product and Construction process stages) of whole
        life emissions as defined in PAS2080. The Use and End-of-Life emissions are currently out of
        scope.
        <br /> <br />
        This tool aims to standardise tracking and reporting across the sector, catalyse knowledge
        sharing, and highlight areas that have previously been overlooked in emissions assessments.
        <br /> <br />
        <h2>Coming Soon:</h2>
        <br />
        This tool is still in its infancy and we’re expecting some big changes in the next phase of
        the project. A summary of the additions we’re hoping to develop are:
        <br />
        <ul>
          <li>Import and Export Capabilities</li>
          <li>Exporting for Regulator Reporting</li>
          <li>Automated Sector Report Publishing</li>
          <li>Utility Asset Tracking</li>
          <li>Low Carbon Technology Alternatives</li>
          <li>Increased Options of Items in each Section</li>
        </ul>
        Along with significant improvement of:
        <ul>
          <li>Existing Conversion Factor Estimates</li>
          <li>Alternative End-of-Life Processes and their respective Conversion Factors</li>
        </ul>
        We are also hoping to develop an Optioneering Calculator in the next phase of the project.
        This supplementary tool will help users identify and compare different options in their
        processes and how the variations affect the total emissions outputted.
        <br /> <br />
        The calculations and assumptions for the conversion factors, along with their references,
        are available{" "}
        <a href="https://static.hauc-uk.org.uk/downloads/EA_Technology_Carbon-Calculator-Reference-Document.xlsx">
          here
        </a>
        .
        <br /> <br />
        Please get in touch with the team at{" "}
        <a href="mailto: roadtonetzero@eatechnology.com">RTNZ Email</a> if you have any problems
        with the tool or wish to share your thoughts.
      </div>
    </PageContainer>
  );
};
