import { useNavigate, useParams } from "react-router-dom";
import {
  Material,
  selectMaterialUsage,
  setStatus,
} from "../features/materialUsage/materialUsageSlice";
import { useFormControl } from "./useFormControl";
import { useSave } from "./useSave";
import { ApiMaterial } from "../models/formTypes";
import { useCalculate } from "./useCalculate";
import { FormStatus } from "../models/formStatus";
import { useDispatch, useSelector } from "react-redux";

export type SaveMaterialUsageRequest = {
  highLevelDetailsID: number;
  materialUsages: ApiMaterial[];
};

const createSavePayload = (materials: Material[], workId: string): SaveMaterialUsageRequest => {
  return {
    highLevelDetailsID: parseInt(workId),
    materialUsages: materials.map((m) => ({
      highLevelDetailsID: parseInt(workId),
      materialTypeID: parseInt(m.materialTypeId || ""),
      materialSubtypeID: parseInt(m.materialSubtypeId || ""),
      quantityUsed: parseInt(m.quantity),
      percentageRecycled: parseFloat(m.percentageRecycled),
      scopeID: parseInt(m.scopeId || ""),
    })),
  };
};

export const useMaterialUsage = () => {
  const materialUsage = useSelector(selectMaterialUsage);
  const { closeEntry } = useFormControl();
  const { save, isSaving } = useSave<SaveMaterialUsageRequest>();
  const navigate = useNavigate();
  const { mode, workId } = useParams();
  const { calculateResults } = useCalculate();
  const dispatch = useDispatch();

  const back = () => {
    navigate(`/${mode}/${workId}/energy-usage`);
  };

  const saveAndClose = async () => {
    if (!workId) return;

    const { success } = await save(
      createSavePayload(materialUsage.form, workId),
      "/materialusage/save"
    );

    await calculateResults(workId);

    if (success) {
      closeEntry();
    }
  };

  const next = async () => {
    if (!workId) return;

    const { success } = await save(
      createSavePayload(materialUsage.form, workId),
      "/materialusage/save"
    );

    if (success) {
      navigate(`/${mode}/${workId}/asset-usage`);
      dispatch(setStatus(FormStatus.Complete));
    }
  };

  return {
    back,
    saveAndClose,
    next,
    isSaving,
  };
};
