export const generateLocalId = () => {
  return Math.floor(Math.random() * 100000);
};

export const round = (value: number, decimalPlaces: number = 2) => {
  const multiplier = Math.pow(10, decimalPlaces);
  return Math.round((value + Number.EPSILON) * multiplier) / multiplier;
};

// converts total for results dashboard to display in excess of 1,000,000 as 1m
// otherwise display to two decimal places with commas
export const shortenResultsDataTotal = (total: number) =>
  total < 1000000 ? Math.trunc(total).toLocaleString() : `${(total / 1000000).toFixed(2)}m`;
