import { useQuery, useMutation } from "react-query";
import { env } from "../env";

import { getAuthHeader } from "../utils/tokenUtils";
import { highLevelDetailsMapper } from "../models/highLevelDetail";
import toast from "react-hot-toast";

export const useHighLevelDetails = () => {
  const fetchHighLevelDetails = async () => {
    const url = `${env.REACT_APP_API_BASE_URL}/highleveldetails/my-organisation`;

    const response = await fetch(url, {
      headers: { Authorization: getAuthHeader() },
    });
    const highLevelDetailsResponse = highLevelDetailsMapper(await response.json());

    return highLevelDetailsResponse;
  };

  const { data, isLoading, refetch } = useQuery("highleveldetails", fetchHighLevelDetails);
  const deleteHighLevelDetailMutation = useMutation(
    async (id: string) => {
      const url = `${env.REACT_APP_API_BASE_URL}/highleveldetails/${id}`;
      const response = await fetch(url, {
        method: "DELETE",
        headers: { Authorization: getAuthHeader() },
      });

      return response.ok;
    },
    {
      onSuccess: (response: boolean) => {
        if (response) {
          toast.success("Entry deleted");
          refetch();
        } else {
          toast.error("Entry not deleted");
        }
      },
      onError: () => {
        toast.error("Error");
      },
    }
  );

  const deleteHighLevelDetail = async (id: string) => {
    if (window.confirm("Are you sure you want to delete?"))
      await deleteHighLevelDetailMutation.mutateAsync(id);
  };

  return { data, isLoading, deleteHighLevelDetail };
};
