import { useState } from "react";
import "./TextInput.scss";

interface TextInputProps {
  id: string;
  label: string;
  value: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  type?: "text" | "date" | "time" | "number" | "password";
  placeholder?: string;
  disabled?: boolean;
  autocomplete?: string;
}

export const TextInput = ({
  id,
  label,
  value,
  onChange,
  type = "text",
  placeholder,
  disabled = false,
  autocomplete,
}: TextInputProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const hasValue = () => !!value.length || isFocused || type === "date" || type === "time";

  const optionalPlaceholder = !value.length && isFocused ? placeholder : undefined;

  return (
    <div className="text-input">
      <label
        className={`text-input__label ${hasValue() ? "text-input__label--filled" : ""}`}
        htmlFor={id}
      >
        {label}
      </label>
      <input
        id={id}
        placeholder={optionalPlaceholder}
        type={type}
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        disabled={disabled}
        autoComplete={autocomplete}
      />
    </div>
  );
};
