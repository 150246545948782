import { useNavigate, useParams } from "react-router-dom";
import { Asset, selectAssetUsage, setStatus } from "../features/assetUsage/assetUsageSlice";
import { useSave } from "./useSave";
import { useFormControl } from "./useFormControl";
import { ApiAsset } from "../models/formTypes";
import { useCalculate } from "./useCalculate";
import { FormStatus } from "../models/formStatus";
import { useDispatch, useSelector } from "react-redux";

type SaveAssetUsageRequest = {
  highLevelDetailsID: number;
  assets: ApiAsset[];
};

const createSavePayload = (assets: Asset[], workId: string): SaveAssetUsageRequest => {
  return {
    highLevelDetailsID: parseInt(workId),
    assets: assets.map((a) => ({
      highLevelDetailsID: parseInt(workId),
      assetTypeID: parseInt(a.assetTypeId || ""),
      assetID: parseInt(a.assetId || ""),
      quantity: parseInt(a.quantity || ""),
      percentageRecycled: parseFloat(a.percentageRecycled || ""),
      scopeID: parseInt(a.scopeId || ""),
    })),
  };
};

export const useAssetUsage = () => {
  const assetUsage = useSelector(selectAssetUsage);
  const { closeEntry } = useFormControl();
  const { save, isSaving } = useSave<SaveAssetUsageRequest>();
  const navigate = useNavigate();
  const { mode, workId } = useParams();
  const { calculateResults } = useCalculate();
  const dispatch = useDispatch();

  const back = () => {
    navigate(`/${mode}/${workId}/material-usage`);
  };

  const saveAndClose = async () => {
    if (!workId) return;

    const { success } = await save(createSavePayload(assetUsage.form, workId), "/asset/save");

    await calculateResults(workId);

    if (success) {
      closeEntry();
    }
  };

  const next = async () => {
    if (!workId) return;

    const { success } = await save(createSavePayload(assetUsage.form, workId), "/asset/save");

    if (success) {
      navigate(`/${mode}/${workId}/waste`);
      dispatch(setStatus(FormStatus.Complete));
    }
  };

  return {
    back,
    saveAndClose,
    next,
    isSaving,
  };
};
