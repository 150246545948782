import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { generateLocalId } from "../../utils/dataUtils";
import { FormStatus } from "../../models/formStatus";

export type Material = {
  id: number;
  materialTypeId: string | null;
  materialSubtypeId: string | null;
  quantity: string;
  percentageRecycled: string;
  scopeId: string | null;
};

export type MaterialUsageState = {
  status: FormStatus;
  form: Material[];
};

const initialState: MaterialUsageState = {
  status: FormStatus.NotStarted,
  form: [],
};

export const materialUsageReducer = {
  setStatus: (state: MaterialUsageState, action: PayloadAction<FormStatus>) => {
    state.status = action.payload;
  },
  resetMaterialUsage: () => {
    return initialState;
  },
  setMaterials: (
    state: MaterialUsageState,
    action: PayloadAction<Material[]>
  ) => {
    state.form = action.payload;
  },
  addMaterial: (state: MaterialUsageState) => {
    const newMaterial: Material = {
      id: generateLocalId(),
      materialTypeId: null,
      materialSubtypeId: null,
      quantity: "",
      percentageRecycled: "",
      scopeId: null,
    };
    state.form.push(newMaterial);
  },
  removeMaterial: (
    state: MaterialUsageState,
    action: PayloadAction<Material>
  ) => {
    const updatedArray = state.form.filter((m) => m.id !== action.payload.id);
    state.form = updatedArray;
  },
  updateMaterial: (
    state: MaterialUsageState,
    action: PayloadAction<Material>
  ) => {
    const updatedMaterial = action.payload;
    const index = state.form.findIndex((a) => a.id === updatedMaterial.id);
    state.form[index] = updatedMaterial;
  },
};

export const materialUsageSlice = createSlice({
  name: "materialUsage",
  initialState: initialState,
  reducers: materialUsageReducer,
});

export const {
  setStatus,
  resetMaterialUsage,
  setMaterials,
  addMaterial,
  removeMaterial,
  updateMaterial,
} = materialUsageSlice.actions;

export const selectMaterialUsage = (state: RootState) => state.materialUsage;
