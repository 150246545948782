import { useDispatch, useSelector } from "react-redux";
import { FieldGroup } from "../../components/FieldGroup/FieldGroup";
import WasteHeaders from "../../components/FieldHeaders/WasteHeaders/WasteHeaders";
import { WasteRow } from "../../components/FieldRows/WasteRow/WasteRow";
import { FormButtons } from "../../components/FormButtons/FormButtons";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { PrimaryButton } from "../../components/PrimaryButton/PrimaryButton";
import { addWaste, removeWaste, selectWaste, updateWaste } from "../../features/waste/wasteSlice";
import { useWaste } from "../../hooks/useWaste";

export const Waste = () => {
  const { form } = useSelector(selectWaste);
  const { back, saveAndClose, next, isSaving } = useWaste();
  const dispatch = useDispatch();

  const infoPaneText = (
    <>
      Please include the waste generated in your road/street works this quarter. Where the type of
      waste is unknown, average construction should be used. <br />
      <br />
      If known, please include the percentage to be recycled. <br />
      <br />
      For Scope, please add whether this is from in-house usage or from contractor usage. If
      unknown, or a combination of the two, please use <i>both</i> <br />
      <br />
      At any point, you can <i>Save and Close</i>. You will be able to return to the same point
      using the Entry Dashboard. <br />
      <br />
      Note, if any significant sources of emissions are missing from this section, please contact
      the team at <a href="mailto: roadtonetzero@eatechnology.com">RTNZ Email</a> <br />
      <br />
    </>
  );

  return (
    <PageContainer heading="Waste" informationalPaneBodyText={infoPaneText} displayNavigationPane>
      <FieldGroup>
        <WasteHeaders />
        {form.map((waste) => (
          <WasteRow
            waste={waste}
            key={waste.id}
            onRemove={() => dispatch(removeWaste(waste))}
            onChange={(updatedWaste) => dispatch(updateWaste(updatedWaste))}
            disabled={isSaving}
          />
        ))}
        <PrimaryButton
          onClick={() => dispatch(addWaste())}
          label="Add New Waste"
          disabled={isSaving}
        />
      </FieldGroup>

      <FormButtons
        onBack={back}
        onSaveAndClose={saveAndClose}
        onSubmit={next}
        disabled={isSaving}
      />
    </PageContainer>
  );
};
