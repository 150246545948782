import { useNavigate, useSearchParams } from "react-router-dom";
import { env } from "../env";
import { HttpMethod } from "../models/httpMethod";
import { HttpStatusCode } from "../models/httpStatusCode";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { clearToken, getAuthHeader, setToken } from "../utils/tokenUtils";
import { clearUser } from "../features/user/userSlice";

type LoginRequest = {
  email: string;
  password: string;
};

type LoginResponse = {
  token: string;
};

type ChangePasswordRequest = {
  currentPassword: string;
  newPassword: string;
};

export const useAuth = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const login = async (email: string, password: string) => {
    const loadingToast = toast.loading("Logging in...");
    setIsLoading(true);

    const payload: LoginRequest = {
      email,
      password,
    };

    try {
      const response = await fetch(`${env.REACT_APP_API_BASE_URL}/authentication/login`, {
        method: HttpMethod.POST,
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const success = response.status === HttpStatusCode.Ok;

      if (!success) {
        setIsLoading(false);
        toast.error("Invalid login details.");
        return;
      }

      const body = (await response.json()) as LoginResponse;
      localStorage.setItem("token", body.token);

      setToken(body.token);

      const redirectUrl = params.get("redirect");
      if (redirectUrl) {
        navigate(redirectUrl);
      } else {
        navigate("/");
      }
    } catch (e) {
      toast.error("Something went wrong while logging in.");
    } finally {
      toast.dismiss(loadingToast);
      setIsLoading(false);
    }
  };

  const logout = useCallback(() => {
    clearToken();
    clearUser();
    localStorage.removeItem("token");
    navigate("/login");
  }, [navigate]);

  const changePassword = async (currentPassword: string, newPassword: string) => {
    const loadingToast = toast.loading("Changing password...");
    setIsLoading(true);

    const payload: ChangePasswordRequest = {
      currentPassword,
      newPassword,
    };
    try {
      const response = await fetch(`${env.REACT_APP_API_BASE_URL}/authentication/changepassword`, {
        method: HttpMethod.POST,
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthHeader(),
        },
      });

      const success = response.status === HttpStatusCode.Ok;

      if (!success) {
        setIsLoading(false);
        toast.error("An error occurred");
        return;
      }

      toast.success("Password changed!");
      navigate(`/`);
    } catch (e) {
      toast.error("An error occurred");
    } finally {
      toast.dismiss(loadingToast);
      setIsLoading(false);
    }
  };

  return {
    login,
    logout,
    changePassword,
    isLoading,
  };
};
