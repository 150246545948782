import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { FormStatus } from "../../models/formStatus";

export interface FormControlState {
  isRestored: boolean;
  isRestoring: boolean;
  errorRestoring: boolean;
}

const initialState: FormControlState = {
  isRestored: false,
  isRestoring: false,
  errorRestoring: false,
};

const formControlReducer = {
  resetFormControl: () => {
    return initialState;
  },
  setIsRestored: (state: FormControlState, action: PayloadAction<boolean>) => {
    state.isRestored = action.payload;
  },
  setIsRestoring: (state: FormControlState, action: PayloadAction<boolean>) => {
    state.isRestoring = action.payload;
  },
  setErrorRestoring: (
    state: FormControlState,
    action: PayloadAction<boolean>
  ) => {
    state.errorRestoring = action.payload;
  },
};

export const formControlSlice = createSlice({
  name: "formControl",
  initialState: initialState,
  reducers: formControlReducer,
});

export const {
  resetFormControl,
  setIsRestored,
  setIsRestoring,
  setErrorRestoring,
} = formControlSlice.actions;

export const selectFormControl = (state: RootState) => state.formControl;
