import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { generateLocalId } from "../../utils/dataUtils";
import { FormStatus } from "../../models/formStatus";

export type Asset = {
  id: number;
  assetTypeId: string | null;
  assetId: string | null;
  quantity: string;
  percentageRecycled: string;
  scopeId: string | null;
};

export type AssetUsageState = {
  status: FormStatus;
  form: Asset[];
};

const initialState: AssetUsageState = {
  status: FormStatus.NotStarted,
  form: [],
};

const assetUsageReducer = {
  setStatus: (state: AssetUsageState, action: PayloadAction<FormStatus>) => {
    state.status = action.payload;
  },
  resetAssetUsage: () => {
    return initialState;
  },
  setAssets: (state: AssetUsageState, action: PayloadAction<Asset[]>) => {
    state.form = action.payload;
  },
  addAsset: (state: AssetUsageState) => {
    const newAsset: Asset = {
      id: generateLocalId(),
      assetTypeId: null,
      assetId: null,
      quantity: "",
      percentageRecycled: "",
      scopeId: null,
    };
    state.form.push(newAsset);
  },
  removeAsset: (state: AssetUsageState, action: PayloadAction<Asset>) => {
    const updatedArray = state.form.filter((a) => a.id !== action.payload.id);
    state.form = updatedArray;
  },
  updateAsset: (state: AssetUsageState, action: PayloadAction<Asset>) => {
    const updatedAsset = action.payload;
    const index = state.form.findIndex((a) => a.id === updatedAsset.id);
    state.form[index] = updatedAsset;
  },
};

export const assetUsageSlice = createSlice({
  name: "assetUsage",
  initialState: initialState,
  reducers: assetUsageReducer,
});

export const {
  setStatus,
  resetAssetUsage,
  setAssets,
  addAsset,
  removeAsset,
  updateAsset,
} = assetUsageSlice.actions;

export const selectAssetUsage = (state: RootState) => state.assetUsage;
