import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { FormStatus } from "../../models/formStatus";
import { Dayjs } from "dayjs";

export type BasicDetails = {
  description: string;
  startDate: string;
  endDate: string;
};

export interface BasicDetailsState {
  status: FormStatus;
  form: BasicDetails;
}

const initialState: BasicDetailsState = {
  status: FormStatus.NotStarted,
  form: {
    description: "",
    startDate: "",
    endDate: "",
  },
};

const basicDetailsReducer = {
  setStatus: (state: BasicDetailsState, action: PayloadAction<FormStatus>) => {
    state.status = action.payload;
  },
  resetBasicDetails: () => {
    return initialState;
  },
  setBasicDetails: (state: BasicDetailsState, action: PayloadAction<BasicDetails>) => {
    state.form = action.payload;
  },
  setDescription: (state: BasicDetailsState, action: PayloadAction<string>) => {
    state.form.description = action.payload;
  },
  setStartDate: (state: BasicDetailsState, action: PayloadAction<string>) => {
    state.form.startDate = action.payload;
  },
  setEndDate: (state: BasicDetailsState, action: PayloadAction<string>) => {
    state.form.endDate = action.payload;
  },
};

export const basicDetailsSlice = createSlice({
  name: "basicDetails",
  initialState: initialState,
  reducers: basicDetailsReducer,
});

export const {
  setStatus,
  resetBasicDetails,
  setBasicDetails,
  setDescription,
  setStartDate,
  setEndDate,
} = basicDetailsSlice.actions;

export const selectBasicDetails = (state: RootState) => state.basicDetails;
