export const isPasswordValid = (enteredPassword: string, confirmedPassword: string) => {
  // Password should be 6 char minimum, include a number, include a non-alphanumeric, at least one upper and lower case character
  const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/;

  if (enteredPassword !== confirmedPassword) return false;

  if (confirmedPassword.length < 6) return false;

  if (!PASSWORD_REGEX.test(confirmedPassword)) return false;

  return true;
};
