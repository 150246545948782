import { useLocation, useParams } from "react-router-dom";
import { selectBasicDetails } from "../features/basicDetails/basicDetailsSlice";
import { selectAssetUsage } from "../features/assetUsage/assetUsageSlice";
import { selectEnergyUsage } from "../features/energyUsage/energyUsageSlice";
import { selectMaterialUsage } from "../features/materialUsage/materialUsageSlice";
import { selectWaste } from "../features/waste/wasteSlice";
import { FormStatus } from "../models/formStatus";
import { useSelector } from "react-redux";

export type Page = {
  title: string;
  key: string;
  status: FormStatus;
};

export const useNavigationPane = () => {
  const { mode, workId } = useParams();
  const { pathname } = useLocation();
  const { status: basicDetailsStatus } = useSelector(selectBasicDetails);
  const { status: assetUsageStatus } = useSelector(selectAssetUsage);
  const { status: energyUsageStatus } = useSelector(selectEnergyUsage);
  const { status: materialUsageStatus } = useSelector(selectMaterialUsage);
  const { status: wasteStatus } = useSelector(selectWaste);

  const isActive = (key: string) => pathname.includes(key);

  const isComplete = (page: Page) => page.status === FormStatus.Complete;

  const pages: Page[] = [
    {
      title: "Basic Details",
      key: "basic-details",
      status: basicDetailsStatus,
    },
    {
      title: "Energy Usage",
      key: "energy-usage",
      status: energyUsageStatus,
    },
    {
      title: "Material Usage",
      key: "material-usage",
      status: materialUsageStatus,
    },
    {
      title: "Asset Usage",
      key: "asset-usage",
      status: assetUsageStatus,
    },
    {
      title: "Waste",
      key: "waste",
      status: wasteStatus,
    },
  ];

  return {
    pages,
    pathname,
    isActive,
    isComplete,
    mode,
    workId,
  };
};
