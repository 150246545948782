import "./RadioButton.scss";

interface RadioButtonProps {
  label: string;
  onChange: () => void;
  checked: boolean;
}

export const RadioButton = ({ label, onChange, checked }: RadioButtonProps) => {
  return (
    <div className="radio">
      <input
        className="radio__input"
        type="radio"
        checked={checked}
        onChange={onChange}
      />
      <label className="radio__label">{label}</label>
    </div>
  );
};
