import "./InputRow.scss";

interface InputRowProps {
  label: string;
  inputId: string;
  children: React.ReactNode;
}

export const InputRow = ({ label, inputId, children }: InputRowProps) => {
  return (
    <div className="input-row">
      <label htmlFor={inputId}>{label}</label>
      {children}
    </div>
  );
};
