import { useState } from "react";
import "./NumberInput.scss";

interface NumberInputProps {
  id: string;
  label: string;
  value: string;
  min?: number;
  max?: number;
  onChange?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  percentage?: boolean;
}

export const NumberInput = ({
  id,
  label,
  value,
  min,
  max,
  onChange,
  placeholder,
  disabled = false,
  percentage = false,
}: NumberInputProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const hasValue = () => value.length || isFocused;

  const optionalPlaceholder = !value && isFocused ? placeholder : undefined;

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    let newValue = Number(event.target.value);

    if (min !== undefined && newValue <= min) {
      newValue = min;
    }

    if (max && newValue > max) {
      newValue = max;
    }

    if (onChange) {
      onChange(newValue.toString());
    }
  };

  return (
    <div className="number-input">
      <label
        className={`number-input__label ${hasValue() ? "number-input__label--filled" : ""}`}
        htmlFor={id}
      >
        {label}
      </label>
      <div className="number-input__input-container">
        <input
          id={id}
          placeholder={optionalPlaceholder}
          type="number"
          value={value}
          onChange={handleOnChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          disabled={disabled}
          min={min}
          max={max}
          // className={percentage ? "number-input__input-box__percentage" : "number-input__input-box"}
          className={"number-input__input-box"}
        />
        {percentage && <p className={"number-input__percentage"}>%</p>}
      </div>
    </div>
  );
};
