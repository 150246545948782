import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";

export type ManagedUserToCreate = {
  email: string;
  password: string;
  organisationId: string;
  roleId: string;
};

interface ManagedUserState {
  user: ManagedUserToCreate;
}

const initialState: ManagedUserState = {
  user: {
    email: "",
    roleId: "",
    organisationId: "",
    password: "",
  },
};

export const managedUserSlice = createSlice({
  name: "manageduser",
  initialState,
  reducers: {
    setManagedUserEmail: (state, action: PayloadAction<string>) => {
      state.user.email = action.payload;
    },
    setManagedUserPassword: (state, action: PayloadAction<string>) => {
      state.user.password = action.payload;
    },
    setManagedUserOrganisationId: (state, action: PayloadAction<string>) => {
      state.user.organisationId = action.payload;
    },
    setManagedUserRoleId: (state, action: PayloadAction<string>) => {
      state.user.roleId = action.payload;
    },
    resetManagedUserFormState: (state) => {
      state.user = initialState.user;
    },
  },
});

export const {
  setManagedUserEmail,
  setManagedUserPassword,
  setManagedUserOrganisationId,
  setManagedUserRoleId,
  resetManagedUserFormState,
} = managedUserSlice.actions;

export const managedUserForm = (state: RootState) => state.managedUserForm;
