import { Material } from "../../../features/materialUsage/materialUsageSlice";
import { useLookup } from "../../../hooks/useLookup";
import { useSubLookup } from "../../../hooks/useSubLookup";
import { LookupKey } from "../../../models/lookup";
import { DeleteButton } from "../../DeleteButton/DeleteButton";
import { NumberInput, Select } from "../../FormComponents";
import "./MaterialRow.scss";

interface MaterialRowProps {
  material: Material;
  onRemove: (a: string | null) => void;
  onChange: (a: Material) => void;
  disabled?: boolean;
}

export const MaterialRow = ({ material, onRemove, onChange, disabled }: MaterialRowProps) => {
  const { id, materialTypeId, materialSubtypeId, quantity, percentageRecycled, scopeId } = material;
  const { values: materialTypes } = useLookup(LookupKey.MaterialType);
  const { values: materialSubtypes } = useSubLookup(LookupKey.MaterialSubtype, materialTypeId);
  const { values: scopes } = useLookup(LookupKey.Scope);

  const handleMaterialTypeChange = (e: React.FormEvent<HTMLSelectElement>) => {
    onChange({
      ...material,
      materialTypeId: e.currentTarget.value,
      materialSubtypeId: null,
    });
  };

  const handleMaterialSubtypeChange = (e: React.FormEvent<HTMLSelectElement>) => {
    onChange({
      ...material,
      materialSubtypeId: e.currentTarget.value,
    });
  };

  const handleQuantityChange = (quantity: string) => {
    return onChange({ ...material, quantity: quantity });
  };

  const handlePercentageRecycledChange = (percentageRecycled: string) => {
    return onChange({ ...material, percentageRecycled: percentageRecycled });
  };

  const handleScopeChange = (e: React.FormEvent<HTMLSelectElement>) => {
    onChange({ ...material, scopeId: e.currentTarget.value });
  };

  return (
    <div className="material-row">
      <Select
        id={`material-type-${id}`}
        label="Material Type"
        options={materialTypes}
        value={materialTypeId}
        onChange={handleMaterialTypeChange}
        disabled={disabled}
      />
      <Select
        id={`material-subtype-${id}`}
        label="Material Subtype"
        options={materialSubtypes}
        value={materialSubtypeId}
        onChange={handleMaterialSubtypeChange}
        disabled={disabled}
      />
      <NumberInput
        id={`quantity-${id}`}
        label="Quantity"
        value={quantity}
        min={0}
        onChange={handleQuantityChange}
        disabled={disabled}
      />
      <NumberInput
        id={`percentage-recycled-${id}`}
        label="Recycled"
        value={percentageRecycled}
        min={0}
        max={100}
        onChange={handlePercentageRecycledChange}
        disabled={disabled}
        percentage={true}
      />
      <Select
        id={`scope-${id}`}
        label="Scope"
        options={scopes}
        value={scopeId}
        onChange={handleScopeChange}
        disabled={disabled}
      />

      <DeleteButton onClick={() => onRemove(materialTypeId)} disabled={disabled} />
    </div>
  );
};
