import { TextInput } from "../FormComponents";
import { PrimaryButton } from "../PrimaryButton/PrimaryButton";
import Logo from "../../assets/images/logo.png";
import "./Login.scss";
import { useAuth } from "../../hooks/useAuth";
import { useState } from "react";

export const Login = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const { login, isLoading } = useAuth();

  return (
    <div className="login">
      <div className="login__sidebar">
        <div className="login__sidebar__logo">
          <img src={Logo} alt="Road To Net Zero Logo" />
        </div>
        <div className="login__sidebar__form">
          <TextInput
            id="username"
            label="Username"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            disabled={isLoading}
          />
          <TextInput
            id="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
            type="password"
            disabled={isLoading}
          />
          <PrimaryButton
            label="Log In"
            onClick={() => login(email, password)}
            disabled={isLoading}
          />
        </div>
        <div className="login__sidebar__bottom">
          For access or more information, please contact:
          <b>
            <a className="login__sidebar__mail-link" href="mailto: roadtonetzero@eatechnology.com">
              roadtonetzero@eatechnology.com
            </a>
          </b>
        </div>
      </div>
    </div>
  );
};
