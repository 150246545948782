import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";

export interface SectorResultFormState {
  utilitySectorId: string;
  startDate: string;
  endDate: string;
}

const initialState: SectorResultFormState = {
  utilitySectorId: "",
  startDate: "",
  endDate: "",
};

const sectorResultFormReducer = {
  setStartDate: (state: SectorResultFormState, action: PayloadAction<string>) => {
    state.startDate = action.payload;
  },
  setUtilitySectorId: (state: SectorResultFormState, action: PayloadAction<string>) => {
    state.utilitySectorId = action.payload;
  },
  setEndDate: (state: SectorResultFormState, action: PayloadAction<string>) => {
    state.endDate = action.payload;
  },
};

export const sectorResultFormSlice = createSlice({
  name: "sectorResultForm",
  initialState: initialState,
  reducers: sectorResultFormReducer,
});

export const { setEndDate, setUtilitySectorId, setStartDate } = sectorResultFormSlice.actions;

export const selectSectorResultForm = (state: RootState) => state.sectorResultsForm;
