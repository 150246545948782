import "./NavButton.scss";
import ChevronRight from "../../assets/icons/chevron-right.svg";
import ChevronRightWhite from "../../assets/icons/chevron-right-white.svg";
import { NavLink } from "react-router-dom";

interface NavButtonProps {
  label: string;
  href?: string;
  active?: boolean;
  onClick?: () => void;
}

export const NavButton = ({ label, href, active, onClick }: NavButtonProps) => {
  return (
    <NavLink
      className={`nav-button ${active ? "nav-button--active" : ""}`}
      to={href ?? "#"}
      onClick={onClick}
      reloadDocument
    >
      <div className="nav-button__icon">
        <img className="nav-button__icon--inactive" src={ChevronRight} alt="" />
        <img
          className="nav-button__icon--active"
          src={ChevronRightWhite}
          alt=""
        />
      </div>
      {label}
    </NavLink>
  );
};
