import { Link } from "react-router-dom";
import "./NavigationPane.scss";
import { Page, useNavigationPane } from "../../hooks/useNavigationPane";
import { FormStatus } from "../../models/formStatus";
import ArrowRightGreen from "../../assets/icons/arrow-right-green.svg";
import CheckWhite from "../../assets/icons/check-white.svg";

export const NavigationPane = () => {
  const { pages, mode, workId, isActive } = useNavigationPane();

  return (
    <div className="navigation-pane">
      <span>Submission Progress</span>

      {pages.map((page) => (
        <PaneRow
          page={page}
          url={workId ? `/${mode}/${workId}/${page.key}` : null}
          isActive={isActive(page.key)}
          status={page.status}
        />
      ))}
    </div>
  );
};

interface PageRowProps {
  page: Page;
  url: string | null;
  isActive: boolean;
  status: FormStatus;
}

const PaneRow = ({ page, url, isActive, status }: PageRowProps) => {
  const getRowClass = () => {
    if (isActive) {
      return "navigation-pane__row--active";
    }
    if (status === FormStatus.Complete) {
      return "navigation-pane_row--complete";
    }
  };

  const getIconClass = () => {
    if (isActive) {
      return "navigation-pane__row__icon--active";
    }
    if (status === FormStatus.Complete) {
      return "navigation-pane__row__icon--complete";
    }
  };

  const getIcon = () => {
    if (isActive) {
      return <img src={ArrowRightGreen} alt="" />;
    }
    if (status === FormStatus.Complete) {
      return <img src={CheckWhite} alt="" />;
    }
    return null;
  };

  return (
    <div className={`navigation-pane__row ${getRowClass()}`}>
      <div className={`navigation-pane__row__icon ${getIconClass()}`}>
        {getIcon()}
      </div>
      <Link
        to={url ?? "#"}
        className={status !== FormStatus.Complete ? "disabled" : ""}
      >
        {page.title}
      </Link>
    </div>
  );
};
