import { useProtectedRoute } from "../../hooks/useProtectedRoute";

interface ProtectedRouteProps {
  allowedRoles: string[];
  children: React.ReactNode;
}

export const ProtectedRoute = ({ children, allowedRoles }: ProtectedRouteProps) => {
  useProtectedRoute(allowedRoles);

  return <>{children}</>;
};
